$(document).ready(function () {
    new WOW().init();
    Vue.use(VueMask.VueMaskPlugin);

    var app = new Vue({
        el: "#app",
        data: {
            isMenuOpened: false,
            products: [
                {
                    id: 1,
                    title: "Дракончик розовый",
                    color: "розовый",
                    material: "хлопок / полиэстер",
                    sizes: [70, 80, 90, 100],
                    selectedSize: 70,
                    oldPrice: 3900,
                    price: 2590,
                    sale: 15,
                    pictures: [1, 1, 1, 1]
                },
                {
                    id: 2,
                    title: "Дракончик серый",
                    color: "розовый",
                    material: "хлопок / полиэстер",
                    sizes: [70, 80, 90, 100],
                    selectedSize: 70,
                    oldPrice: 3900,
                    price: 2590,
                    sale: 15,
                    pictures: [1, 1, 1]
                },
                {
                    id: 3,
                    title: "Зайчик",
                    color: "розовый",
                    material: "хлопок / полиэстер",
                    sizes: [70, 80, 90, 100],
                    selectedSize: 70,
                    oldPrice: 3900,
                    price: 2590,
                    sale: 15,
                    pictures: [1, 1, 1]
                },
                {
                    id: 4,
                    title: "Полосатик",
                    color: "розовый",
                    material: "хлопок / полиэстер",
                    sizes: [70, 80, 90, 100],
                    selectedSize: 70,
                    oldPrice: 3900,
                    price: 2590,
                    sale: 15,
                    pictures: [1, 1, 1]
                },
                {
                    id: 5,
                    title: "Лисичка с ушками",
                    color: "розовый",
                    material: "хлопок / полиэстер",
                    sizes: [70, 80, 90, 100],
                    selectedSize: 70,
                    oldPrice: 3900,
                    price: 2590,
                    sale: 15,
                    pictures: [1, 1, 1]
                },
                {
                    id: 6,
                    title: "Кролик",
                    color: "розовый",
                    material: "хлопок / полиэстер",
                    sizes: [70, 80, 90, 100],
                    selectedSize: 70,
                    oldPrice: 3900,
                    price: 2590,
                    sale: 15,
                    pictures: [1, 1, 1]
                },
                {
                    id: 7,
                    title: "Арбузик",
                    color: "розовый",
                    material: "хлопок / полиэстер",
                    sizes: [70, 80, 90, 100],
                    selectedSize: 70,
                    oldPrice: 3900,
                    price: 2590,
                    sale: 15,
                    pictures: [1, 1, 1]
                },
                {
                    id: 8,
                    title: "I Love Milk",
                    color: "розовый",
                    material: "хлопок / полиэстер",
                    sizes: [70, 80, 90, 100],
                    selectedSize: 70,
                    oldPrice: 3900,
                    price: 2590,
                    sale: 15,
                    pictures: [1, 1, 1]
                },
                {
                    id: 9,
                    title: "Летящее Облачко",
                    color: "розовый",
                    material: "хлопок / полиэстер",
                    sizes: [70, 80, 90, 100],
                    selectedSize: 70,
                    oldPrice: 3900,
                    price: 2590,
                    sale: 15,
                    pictures: [1, 1, 1]
                },
                {
                    id: 10,
                    title: "Little Dreamer",
                    color: "розовый",
                    material: "хлопок / полиэстер",
                    sizes: [70, 80, 90, 100],
                    selectedSize: 70,
                    oldPrice: 3900,
                    price: 2590,
                    sale: 15,
                    pictures: [1, 1, 1]
                },
                {
                    id: 11,
                    title: "Made with Love",
                    color: "розовый",
                    material: "хлопок / полиэстер",
                    sizes: [70, 80, 90, 100],
                    selectedSize: 70,
                    oldPrice: 3900,
                    price: 2590,
                    sale: 15,
                    pictures: [1, 1, 1]
                },
            ],
            selectedProduct: {},
            productsFilter: 999,
            form: {
                order: {
                    formName: "Хотите сделать заказ или есть дополнительные вопросы?",
                    name: "",
                    phone: "",
                    checkbox: true,
                    attemptSubmit: false,
                },
                product: {
                    formName: "",
                    name: "",
                    phone: "",
                    code: "",
                    product: {},
                    checkbox: true,
                    attemptSubmit: false,
                }
            },
            sizes: {
                calc: 4
            }
        },
        methods: {
            /**
             * Функция открытия модального окна
             * @param event Событие
             * @param el Название модального окна
             */
            openPopup: function(el, event) {
                event.preventDefault();

                switch (el) {
                    default:
                        $.fancybox.open($(".popup-" + el), {
                            touch: true
                        });
                        break;
                }
            },
            /**
             * Скролл к блоку
             * @param to | Блок к которому нужно скроллить
             * @param event
             */
            scrollTo: function (to, event) {
                this.isMenuOpened = false;

                $.fancybox.close();

                if (event) event.preventDefault();
                var top = $(to).offset().top + 1;

                if($(document).innerWidth() <= 768) {
                    top = top - $(".header").innerHeight();
                }

                if(to == ".promo") {
                    top = 0;
                }

                $("body, html").animate({scrollTop: top}, 500);
            },
            /**
             * Ввод телефона
             * @param form
             */
            enterPhone: function (form) {
                (form.phone.length == 0) ? form.phone = " " : form.phone
            },
            isImportantValid: function (val) {
                return val.length > 0;
            },
            /**
             * Проверка на валидность email
             * @param email
             * @return {boolean}
             */
            isEmailValid: function (email) {
                return /^(|(([A-Za-z0-9]+_+)|([A-Za-z0-9]+\-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+\-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6})$/.test(email) && email.length > 0
            },
            /**
             * Валидация имени
             * @param name
             * @return {boolean}
             */
            isNameValid: function (name) {
                // return !/^[A-Za-z0-9]+$/.test(name) && name.length >= 2
                return /^[_a-zA-Z0-9а-яА-ЯёЁ]+$/.test(name) && name.length >= 2
            },
            /**
             * Валидация телефона
             * @param phone
             * @return {boolean}
             */
            isPhoneValid: function (phone) {
                return /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11}(\s*)?$/.test(phone) && phone.length > 0
            },
            /**
             * Валидация
             * @param formName
             * @return {boolean}
             */
            validate: function (formName) {
                this.form[formName].attemptSubmit = true;
                var valid = "";

                switch (formName) {
                    case "product":
                            valid = this.isNameValid(this.form[formName].name) && this.isPhoneValid(this.form[formName].phone) && this.form[formName].checkbox;

                            if(valid) {
                                this.form.product.product = this.selectedProduct;
                            }
                        break;
                    default:
                            valid = this.isNameValid(this.form[formName].name) && this.isPhoneValid(this.form[formName].phone) && this.form[formName].checkbox;
                        break;
                }

                if(valid) {
                    if (formName == "callbackCalc") {
                        this.form.callbackCalc.calc.object = this.cost.selectedObject.title;
                        this.form.callbackCalc.calc.type = this.cost.selectedType.title;
                        this.form.callbackCalc.calc.price = "от " + this.cost.selectedType.price + " руб/м";
                    }

                    this.sendForm(this.form[formName]);
                } else {
                    return false;
                }
            },
            /**
             * Отправка формы
             * @param form
             */
            sendForm: function (form) {
                axios({
                    method: "post",
                    url: "./sender.php",
                    data: form,
                    config: { headers: {"Content-Type": "application/x-www-form-urlencoded" }}
                }).then(function (response) {
                    window.location.replace("./thanks.html");
                }).catch(function (response) {});
            },
            selectProduct: function (product) {
                this.selectedProduct = product;

                $.fancybox.open($(".popup-product"), {
                    touch: false,
                    afterShow: function(instance, current) {
                        if(!$(".popup-product-slider").hasClass("slick-initialized")) {
                            $(".popup-product-slider").slick({
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                arrows: false,
                                fade: true,
                                dots: false,
                                asNavFor: ".popup-product-slider-nav",
                                focusOnSelect: true
                            });

                            $(".popup-product-slider-nav").slick({
                                slidesToShow: 4,
                                slidesToScroll: 1,
                                asNavFor: ".popup-product-slider",
                                dots: false,
                                arrows: false,
                                focusOnSelect: true
                            });
                        }

                        $(".popup-product-slider")
                            .slick("slickUnfilter")
                            .slick("slickFilter", ".filter-" + product.id);

                        $(".popup-product-slider-nav")
                            .slick("slickUnfilter")
                            .slick("slickFilter", ".filter-" + product.id);
                    }
                });
            }
        },
        computed: {
            sizeResult: function () {

                var result = 0;

                switch (this.sizes.calc) {
                    case 1:
                            result = 60;
                        break;
                    case 2:
                            result = 66;
                        break;
                    case 3:
                            result = 73;
                        break;
                    case 4:
                            result = 80;
                        break;
                    case 5:
                            result = 90;
                        break;
                    case 6:
                            result = 95;
                        break;
                    case 7:
                            result = 100;
                        break;
                }

                return result;
            }
        },
        created: function () {
            if($(document).innerWidth() <= 700) {
                this.productsFilter = 3;
            }
        }
    });

    $(".sizes-calc__line").slider({
        animate: "fast",
        range: "min",
        value: app.sizes.calc,
        min: 1,
        max: 7,
        classes: {
            "ui-slider": "sizes-calc__line-line",
            "ui-slider-handle": "sizes-calc__line-handle",
            "ui-slider-range": "sizes-calc__line-range"
        },
        slide: function(event, ui) {
            app.sizes.calc = ui.value;
        }
    });

    $(".reviews-slider").slick({
        dots: true,
        prevArrow: '<div class="slider__arrow slider__arrow-prev"></div>',
        nextArrow: '<div class="slider__arrow slider__arrow-next"></div>',
        adaptiveHeight: true,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    adaptiveHeight: true,
                    dots: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    adaptiveHeight: true,
                    dots: true,
                }
            }
        ]
    });

    $(".questions-items__item").click(function () {
        var $this = $(this);

        if($this.hasClass("questions-items__item_active")) {
            $this.find(".questions-items__item-body").slideUp();
            $this.removeClass("questions-items__item_active");
        } else {
            $this.find(".questions-items__item-body").slideDown();
            $this.addClass("questions-items__item_active");
        }
    });

    var menu_selector = ".menu-list";
    function onScroll() {
        var scroll_top = $(document).scrollTop();
        $(menu_selector + " a").each(function() {
            var hash = $(this).attr("href");
            var target = $(hash);
            if (target.position().top <= scroll_top && target.position().top + target.outerHeight() > scroll_top) {
                $(menu_selector + " .menu-list__item-link").removeClass("menu-list__item-link_active");
                $(this).addClass("menu-list__item-link_active");
            } else {
                $(this).removeClass("menu-list__item-link_active");
            }
        });

        var promoHeight = $(".promo").innerHeight();
        var $menu = $(".menu");
        if($(document).innerWidth() > 959) {
            if (scroll_top > promoHeight) {
                $menu.fadeIn();
            } else {
                $menu.hide();
            }
        }
    }

    if($(document).innerWidth() <= 540) {
        $(".sizes-calc__lines-item span").text("мес");
    }

    $(document).on("scroll", onScroll);

    if($(document).innerWidth() <= 700) {
        $(".advantages-items, .easy-items").slick({
            dots: true,
            prevArrow: '<div class="slider__arrow slider__arrow-prev"></div>',
            nextArrow: '<div class="slider__arrow slider__arrow-next"></div>',
            adaptiveHeight: true,
            slidesToShow: 1,
        });
    }
});